<template>
  <header>
    <the-main-nav></the-main-nav>
  </header>
  <section class="mainSection">
    <div class="content">

<h1>Terms of Use</h1>
<p>By using Agni and opting-in during the installation of the Agni plugin, you accept these terms of use. By installing
Agni, an account will be created for you at agni.rocks

This account is not limited in time (see "Privacy and Data Policies" in this file regarding account deletion in certain
cases), is free of charge and provides you with the service of testing your WordPress login page automatically and
regularly.

You can delete your account at any time by removing Agni or by requesting it to be deleted via support@agni.rocks

There are several rules that you should be aware of when using the agni.rocks services directly or via Agni. If these
rules are broken, your account at agni.rocks will be terminated without prior notice.
</p>

<p>By opting-in for using Agni you agree to:

<ol>
  <li>To ensure that the email address used for registration is accessible.</li>

  <li>Not to make multiple registrations for one and the same single-site WordPress installation.</li>
  <li>Not to install Agni on a site which does not belong to you, or on which you are not a designated administrator.</li>
  <li>Not to use Agni for other purposes than to test your WordPress login page.</li>
  <li>Not to use offensive, abusive or clearly misleading information during registration or in communication with the
agni.rocks teams or Agni teams.</li>
  <li>Not to access the agni.rocks services outside of the scope of the Agni WordPress plugin.</li>

  <li>Not to interfere with or disrupt the agni.rocks services, communication infrastructure connected to it, or to disobey
any requirements, procedures, policies or regulations of networks or clients in use by you connected to the agni.rocks
services.</li>

  <li>Not to engage in any form of solicitation of any illegal act.</li>
  <li>Not to violate any applicable local, state, national or international law.</li>
  <li>Not to share any of your account information, including registration token and site validation token with others.</li>
  <li>To immediately inform us if you suspect someone is misusing your account.

  </li>
</ol>

</p>

<p class="warranty">
  DISCLAIMER OF WARRANTY
agni.rocks assumes no responsibility for errors or omissions in any provided information, software or other data
(hereafter referred to as "materials"). ANY MATERIALS LOCATED ON OUR SITES OR VIA AGNI ARE PROVIDED "AS IS" WITHOUT
WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.

agni.rocks further does not warrant the accuracy or completeness of the information, text, graphics, links or other
items contained within these materials. agni.rocks shall not be liable for any special, indirect, incidental, or
consequential damages, including without limitation, lost revenues or lost profits, which may result from the use of
these materials. agni.rocks does not provide or support the provision of legal advice.

The materials on the sites of agni.rocks and via Agni are subject to change without notice and do not represent a
commitment on the part of agni.rocks in the future. agni.rocks does not warrant and disclaims any responsibility or
liability in regard to system performance, availability, data protection, or reliability.

By using agni.rocks, its services and sites including Agni, you release agni.rocks from any liability or loss of, or
damage to, or errors in, data, information, images, videos or any information you provide which pertains to you.

If you have any questions regarding these terms, please do not hesitate to contact us at support@agni.rocks
</p>

    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TheMainNav from '@/components/layout/TheMainNav.vue';

export default defineComponent({
  components: {
    TheMainNav,
  },
});
</script>

<style lang="scss" scoped>
* {
  font-family: "pm";
  display: block;
  font-size: 13px;

  @media (max-width: 500px) {
    font-size: 11px;
  }
}
.mainSection {
  padding: 100px 0;
}
h1 {
  display: block;
  color: #5828ff;
  font-size: 4em;
  font-family: "pm-b";
  margin-top: 50px;
  margin-bottom: 25px;
}
h2 {
  color: #5828ff;
  font-size: 2em;
  margin-top: 30px;
  margin-bottom: 10px;
  line-height: 1.5em;
}
p {
  color: #747474;
  font-size: 18px;
  max-width: 900px;

  &.warranty {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 30px;
  }
}

ol {
  list-style: decimal;
  color: #747474;
  max-width: 900px;
  margin-top: 30px;

  li {
    display: list-item;
    font-size: 18px;
    margin: 10px 0px 10px 45px;
  }
}
</style>
